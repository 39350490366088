// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bx_kz";
export var caseStudyBackground__lineColor = "bx_kq";
export var caseStudyHead = "bx_kC";
export var caseStudyHead__imageWrapper = "bx_kn";
export var caseStudyProjectsSection = "bx_kB";
export var caseStudyQuote__bgRing = "bx_kw";
export var caseStudyQuote__bottomVideo = "bx_kL";
export var caseStudyVideoReview = "bx_lz";
export var caseStudyVideoReview__bgRing = "bx_lB";
export var caseStudyVideo__ring = "bx_kF";
export var caseStudy__bgDark = "bx_km";
export var caseStudy__bgLight = "bx_kl";
export var caseStudy__bgLightReverse = "bx_ly";